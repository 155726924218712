@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  overflow-x: hidden;
  font-family: 'Montserrat', sans-serif;
  &:before{
    content:'';
    content: "";
    width: 100%;
    height: 100vh;
    background: linear-gradient(to bottom, #070502, #070502);
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }
}

.text-shadow{
  text-shadow: 0px 2px 0px rgb(0 0 0 / 30%);
}

.lefto {
  left:-200px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.navBarCont{
  width: 100%;
  height: 60px;
  box-shadow: 0 1px 3px rgba(15, 15, 15, 0.13);
  display: flex;
  align-items: center;
  padding: 0 1.5em;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
}

.progress-bar {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 10px;
  background: var(--red);
  transform-origin: 0%;
}

.image-container {

  width: 100%;

  > div {
    position: unset !important;
  }

  .image {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
}

@media screen and (max-width: 640px) { 
  .xxl {
    font-size: 8rem;
    line-height: 1;
  }
}

@media screen and (min-width: 640px) { 
  .xxl {
    font-size: 13rem;
    line-height: 1;
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

@keyframes pulse-slow {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.animate-pulse-slow {
  animation: pulse-slow 3s ease-in-out infinite;
}